<template>
  <div class="about">
    <h1>This is an about page</h1>
    <view-box>
    	<h1 slot="header">This is header</h1>
    	<div>content</div>
    	<h1 slot="footer">This is header</h1>
    </view-box>
  </div>
</template>
<script type="text/javascript">
	import ViewBox from '../components/view-box'
	export default {
	    components: {
            ViewBox
	    }
	}

</script>
