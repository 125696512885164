<template>
  <div>
	<div class="header" style="margin-top">
     	<nav-header/>
	</div>
	<div class="content">
	    <slot></slot>
	</div>
	<div class="footer">
	    <slot name="footer"></slot>
	</div>
  </div>
</template>
import NavHeader from '../components/NavHeader'
<script type="text/javascript">
	export default {

	}
</script>